import warningAlert from '../../../images/warning_alert.svg';
import { Colors } from '../../../themes/colors';
import { useNotificationTypeMap } from '../hooks/useNotificationSettings';
import { CustomSwitch } from './CustomSwitch';

export const NotificationItem = ({ item, handleOnSwitchChange }) => {
  const { event, group, ...settings } = item;
  const { notificationTypeMap, shouldShowWarning } = useNotificationTypeMap()
  const showWarning = shouldShowWarning(event, settings);
  const notification = notificationTypeMap[event];

  const handleOnChange = (code, checked) => {
    handleOnSwitchChange(group, code, checked, event);
  };

  if (!notification) {
    return <></>;
  }

  return (
    <div
      style={{
        width: '100%',
        margin: '0px 20px',
        display: 'flex',
        padding: '20px 20px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '60%', gap: 8 }}>
        <div style={{ display: 'flex', marginTop: 18, gap: 8 }}>
          <span
            className="global_font"
            style={{ fontSize: 18, fontWeight: 700, color: Colors.neutral_600, margin: 0 }}
          >
            {notification.title}
          </span>
          {showWarning && (
            <img
              src={warningAlert}
              style={{
                width: 18,
                objectFit: 'contain',
                animation: 'fadeIn 0.5s ease-in-out',
              }}
              alt="warning_alert"
            />
          )}
        </div>

        <span className="global_font" style={{ fontSize: 16, color: Colors.neutral_500 }}>
          {notification.description}
        </span>
      </div>

      <div
        style={{
          marginLeft: 120,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: 18,
        }}
      >
        {settings?.push !== undefined && notification.isPush && (
          <CustomSwitch code="push" checked={settings?.push} handleOnChange={handleOnChange} />
        )}

        {settings?.email !== undefined && notification.isEmail && (
          <CustomSwitch code="email" checked={settings?.email} handleOnChange={handleOnChange} />
        )}

        {settings?.text !== undefined && notification.isText && (
          <CustomSwitch code="text" checked={settings?.text} handleOnChange={handleOnChange} />
        )}
      </div>
    </div>
  );
};
