import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../themes/global.scss';
import '../../themes/registration.scss';

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EDIT_USER, editUser, fetchMetadata, showMessage } from '../../actions';
import BackButton from '../../commonComponents/BackButton';
import Button from '../../commonComponents/Button';
import CardSpinner from '../../commonComponents/CardSpinner';
import Checkcircle from '../../commonComponents/Checkcircle';
import InputSelect from '../../commonComponents/InputSelect';
import { UserProfession } from '../../enums/UserProfession';
import useLoading from '../../hooks/useLoading';
import { Colors } from '../../themes/colors';
import { isObjectEqual } from '../../utils/Object';
import {
  bookedOutPatientsOptions,
  numberOfOperatoriesOptions,
  numberOfPermanentStaffOptions,
} from '../registration/utils';
import { ChangeAccountPhoto } from './components/ChangeAccountPhoto';

const opacityVariants = {
  show: { opacity: 1 },
  hide: { opacity: 0 },
};

export default () => {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const allMetadata = useSelector((state) => state.user.allMetadata);
  const [titleName, setNameTitle] = useState(user?.title || 'Dr.');
  const [firstName, setFirstName] = useState(user?.first_name || '');
  const [lastName, setLastName] = useState(user?.last_name || '');
  const [placeId, setPlaceId] = useState(user?.address[0]?.place_id || '');
  const [formattedAddress, setFormattedAddress] = useState(
    user?.address[0]?.formatted_address || '',
  );
  const [notes, setOfficeNotes] = useState(user?.notes_for_temp || '');
  const [website, setWebsite] = useState(user?.web_site || '');
  const [titleNameAccountAdmin, setNameTitleAccountAdmin] = useState(
    user?.account_admin[0]?.title || 'Dr.',
  );
  const [firstNameAccountAdmin, setFirstNameAccountAdmin] = useState(
    user?.account_admin[0]?.first_name || '',
  );
  const [lastNameAccountAdmin, setLastNameAccountAdmin] = useState(
    user?.account_admin[0]?.last_name || '',
  );
  const [officeName, setOfficeName] = useState(user?.office_name || '');
  const [isParkingFree, setIsParkingFree] = useState(user?.parkingInformation?.isParkingFree);
  const [parkingCost, setParkingCost] = useState(user?.parkingInformation?.cost || '');
  const [parkingCostType, setParkingCostType] = useState(
    user?.parkingInformation?.costType || 'hr',
  );
  const [parkingType, setParkingType] = useState(user?.parkingInformation?.parkingType || '');
  const dispatch = useDispatch();
  const { isLoading } = useLoading([EDIT_USER]);
  const officeProductivity = {
    operatories: user?.office_info?.productivity?.operatories || null,
    permanentStaff: {
      [UserProfession.RDH]: user?.office_info?.productivity?.permanentStaff?.RDH || null,
      [UserProfession.DA]: user?.office_info?.productivity?.permanentStaff?.DA || null,
      [UserProfession.DN]: user?.office_info?.productivity?.permanentStaff?.DN || null,
    },
    bookedOutPatients: user?.office_info?.productivity?.bookedOutPatients || null,
  };
  const [productivity, setProductivity] = useState(officeProductivity);

  const validateGeneralOfficeInfo = () => {
    const parkingCostInformation =
      isParkingFree !== undefined && (isParkingFree || (parkingCost && parkingCostType));

    if (!firstName || (!firstNameAccountAdmin && lastNameAccountAdmin)) {
      dispatch(showMessage({ title: 'Error', message: 'Invalid first name!', isError: true }));
    } else if (!lastName || (!lastNameAccountAdmin && firstNameAccountAdmin)) {
      dispatch(showMessage({ title: 'Error', message: 'Invalid last name!', isError: true }));
    } else if (!officeName) {
      dispatch(showMessage({ title: 'Error', message: 'Invalid office name!', isError: true }));
    } else if (!parkingCostInformation || !parkingType) {
      dispatch(
        showMessage({ title: 'Error', message: 'Invalid parking information!', isError: true }),
      );
    } else {
      const data = {
        user_type: 'DNT',
        title: titleName,
        first_name: firstName,
        last_name: lastName,
        office_name: officeName,
        account_admin_title: titleNameAccountAdmin,
        account_admin_first_name: firstNameAccountAdmin,
        account_admin_last_name: lastNameAccountAdmin,
        parkingInformation: {
          isParkingFree,
          cost: Number(parkingCost),
          costType: parkingCostType,
          parkingType,
        },
      };

      handleSubmitButton(data);
    }
  };

  const handleSubmitButton = () => {
    const data = {
      title: titleName,
      first_name: firstName,
      last_name: lastName,
      office_name: officeName,
      account_admin_title: titleNameAccountAdmin,
      account_admin_first_name: firstNameAccountAdmin,
      account_admin_last_name: lastNameAccountAdmin,
      web_site: website,
      place_id: placeId,
      notes_for_temp: notes,
      parkingInformation: {
        isParkingFree,
        cost: Number(parkingCost),
        costType: parkingCostType,
        parkingType,
      },
      ...(!isObjectEqual(officeProductivity, productivity) && { productivity }),
    };
    dispatch(editUser({ data }));
  };

  useEffect(() => {
    if (!allMetadata) {
      dispatch(fetchMetadata());
    }

    setFirstName(user?.first_name || '');
    setNameTitle(user?.title || 'Dr.');
    setLastName(user?.last_name || '');
    setPlaceId(user?.address[0]?.place_id || '');
    setFormattedAddress(user?.address[0]?.formatted_address || '');
    setOfficeNotes(user?.notes_for_temp || '');
    setNameTitleAccountAdmin(user?.account_admin[0]?.title || 'Dr.');
    setFirstNameAccountAdmin(user?.account_admin[0]?.first_name || '');
    setLastNameAccountAdmin(user?.account_admin[0]?.last_name || '');
    setOfficeName(user?.office_name || '');
    setIsParkingFree(user?.parkingInformation?.isParkingFree);
    setParkingCost(user?.parkingInformation?.cost || '');
    setParkingCostType(user?.parkingInformation?.costType || 'hr');
    setParkingType(user?.parkingInformation?.parkingType || '');
  }, [user, allMetadata, dispatch]);

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ flexDirection: 'column', justifyContent: 'flex-start' }}
      >
        <div
          style={{
            padding: '20px',
          }}
        >
          <BackButton url="/account" />

          <div
            style={{
              minWidth: '725px',
              padding: '0 100px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
              }}
            >
              <h1 className="global_font f-dark" style={{ marginBottom: 30, textAlign: 'center' }}>
                Edit Account
              </h1>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 40,
                }}
              >
                <div>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      gap: 20,
                    }}
                  >
                    <ChangeAccountPhoto />

                    <div
                      style={{
                        width: '100%',
                        marginTop: 12,
                      }}
                    >
                      <span style={styles.label}>Office Name</span>
                      <input
                        className="input-field"
                        type="text"
                        style={{
                          width: '-webkit-fill-available',
                          fontWeight: 400,
                          fontSize: 16,
                          marginTop: 12,
                        }}
                        value={officeName}
                        placeholder="Dental Office Name"
                        onChange={(e) => setOfficeName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <span style={styles.label}>Dental Office Address</span>
                  <span style={styles.text}>{formattedAddress}</span>
                </div>

                <div>
                  <span style={styles.label}>Office Contact Information</span>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 12,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                      }}
                    >
                      <span style={{ ...styles.label, marginBottom: 0, marginRight: 4 }}>
                        Email:
                      </span>
                      <span style={styles.text}>{user?.email_id}</span>
                    </div>
                    <span style={{ ...styles.label, marginBottom: 0 }}>/</span>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                      }}
                    >
                      <span style={{ ...styles.label, marginBottom: 0, marginRight: 4 }}>
                        Mobile Number:
                      </span>
                      <span style={styles.text}>{user?.contact_number}</span>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <span style={styles.label}>
                    Dentist Name
                    <span style={{ ...styles.text, color: Colors.neutral_500, marginLeft: 4 }}>
                      (Can be edited at anytime)
                    </span>
                  </span>
                  <div
                    style={{
                      marginTop: 10,
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 40,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '33%',
                      }}
                    >
                      <span style={styles.label}>Title</span>

                      <InputSelect
                        value={titleName}
                        setValue={(value) => setNameTitle(value)}
                        options={[
                          { title: 'Dr.', value: 'Dr.' },
                          { title: 'Mr.', value: 'Mr.' },
                          { title: 'Mrs.', value: 'Mrs.' },
                          { title: 'Ms.', value: 'Ms.' },
                        ]}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '33%',
                      }}
                    >
                      <span style={styles.label}>First Name</span>
                      <input
                        className="input-field"
                        type="text"
                        value={firstName}
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          width: '-webkit-fill-available',
                        }}
                        placeholder="First Name"
                        onChange={(e) => {
                          const name = e.target.value
                            .split(/\s+/)
                            .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
                            .join(' ');
                          setFirstName(name);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '33%',
                      }}
                    >
                      <span style={styles.label}>Last Name</span>
                      <input
                        className="input-field"
                        type="text"
                        value={lastName}
                        placeholder="Last Name"
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          width: '-webkit-fill-available',
                        }}
                        onChange={(e) => {
                          const name = e.target.value
                            .split(/\s+/)
                            .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
                            .join(' ');
                          setLastName(name);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <span style={styles.label}>
                    Additional Contact Name
                    <span style={{ ...styles.text, color: Colors.neutral_500, marginLeft: 4 }}>
                      (Can be edited at anytime)
                    </span>
                  </span>
                  <div
                    style={{
                      marginTop: 10,
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 40,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '33%',
                      }}
                    >
                      <span style={styles.label}>Title</span>

                      <InputSelect
                        value={titleNameAccountAdmin}
                        setValue={(value) => setNameTitleAccountAdmin(value)}
                        options={[
                          { title: 'Dr.', value: 'Dr.' },
                          { title: 'Mr.', value: 'Mr.' },
                          { title: 'Mrs.', value: 'Mrs.' },
                          { title: 'Ms.', value: 'Ms.' },
                        ]}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '33%',
                      }}
                    >
                      <span style={styles.label}>First Name</span>
                      <input
                        className="input-field"
                        type="text"
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          width: '-webkit-fill-available',
                        }}
                        value={firstNameAccountAdmin}
                        placeholder="First Name"
                        onChange={(e) => {
                          const name = e.target.value
                            .split(/\s+/)
                            .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
                            .join(' ');
                          setFirstNameAccountAdmin(name);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '33%',
                      }}
                    >
                      <span style={styles.label}>Last Name</span>
                      <input
                        className="input-field"
                        type="text"
                        placeholder="Last Name"
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          width: '-webkit-fill-available',
                        }}
                        value={lastNameAccountAdmin}
                        onChange={(e) => {
                          const name = e.target.value
                            .split(/\s+/)
                            .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
                            .join(' ');
                          setLastNameAccountAdmin(name);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <span style={styles.label}>Number of Operatories</span>

                  <InputSelect
                    value={productivity.operatories}
                    setValue={(value) =>
                      setProductivity((prev) => ({ ...prev, operatories: value }))
                    }
                    options={numberOfOperatoriesOptions?.map((item) => ({
                      title: item,
                      value: item,
                    }))}
                  />
                </div>

                <div>
                  <span style={styles.label}>Number of Permanent Staff at this Office</span>

                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr',
                      gap: 26,
                    }}
                  >
                    {[
                      { title: 'Dental Hygienists', value: UserProfession.RDH },
                      { title: 'Dental Assistants', value: UserProfession.DA },
                      { title: 'Doctors', value: UserProfession.DN },
                    ].map((item) => (
                      <div key={item.value} style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <span
                          style={{
                            fontFamily: 'Nunito',
                            fontSize: 16,
                            fontWeight: 400,
                            marginBottom: 10,
                            color: Colors.neutral_600,
                          }}
                        >
                          {item.title}:
                        </span>
                        <InputSelect
                          value={productivity.permanentStaff[item.value]}
                          setValue={(value) =>
                            setProductivity((prev) => ({
                              ...prev,
                              permanentStaff: {
                                ...prev.permanentStaff,
                                [item.value]: value,
                              },
                            }))
                          }
                          options={numberOfPermanentStaffOptions?.map((item) => ({
                            title: item,
                            value: item,
                          }))}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <span style={styles.label}>How Far Are You Currently Booking Out Patients?</span>

                  <InputSelect
                    value={productivity.bookedOutPatients}
                    setValue={(value) =>
                      setProductivity((prev) => ({ ...prev, bookedOutPatients: value }))
                    }
                    options={bookedOutPatientsOptions?.map((item) => ({
                      title: item,
                      value: item,
                    }))}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <span style={styles.label}>Parking</span>

                  <p
                    style={{
                      fontFamily: 'Nunito',
                      color: Colors.neutral_600,
                      margin: '0px 0px 12px 0px',
                    }}
                  >
                    Free parking is available.
                  </p>

                  <div
                    style={{ display: 'flex', cursor: 'pointer', marginBottom: 8 }}
                    onClick={() => setIsParkingFree(true)}
                  >
                    <Checkcircle checked={isParkingFree} />

                    <p className="global_font f-dark" style={{ margin: '0px 0px 0px 15px' }}>
                      Yes
                    </p>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      height: 42,
                      marginBottom: 22,
                      width: '100%',
                    }}
                    onClick={() => setIsParkingFree(false)}
                  >
                    <Checkcircle checked={isParkingFree === false} />

                    <p
                      className="global_font f-dark"
                      style={{ alignSelf: 'center', margin: '0px 32px 0px 15px' }}
                    >
                      No
                    </p>

                    <motion.div
                      animate={isParkingFree === false ? 'show' : 'hide'}
                      variants={opacityVariants}
                      style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                      <span className="input_names" style={{ minWidth: 'fit-content' }}>
                        Parking cost:
                      </span>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          gap: 12,
                        }}
                      >
                        <input
                          className="input-field"
                          type="text"
                          value={parkingCost ? `US$ ${parkingCost}` : ''}
                          placeholder="US$ --,--"
                          onChange={(e) => {
                            const value = e.target.value?.replace('US$ ', '');

                            if (/^\d+(\.\d{0,2})?$/.test(value) || value === '') {
                              setParkingCost(value);
                            }
                          }}
                          onClick={(e) => {
                            e.currentTarget.value = '';
                            e.currentTarget.value = parkingCost ? `US$ ${parkingCost}` : '';
                          }}
                          style={{
                            width: '85%',
                            margin: 0,
                            marginRight: 12,
                            fontWeight: 400,
                            fontSize: 16,
                          }}
                        />

                        <InputSelect
                          value={parkingCostType}
                          setValue={(value) => setParkingCostType(value)}
                          options={[
                            { title: 'per hour', value: 'hr' },
                            { title: 'per day', value: 'day' },
                          ]}
                        />
                      </div>
                    </motion.div>
                  </div>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span style={styles.label}>Parking Type:</span>

                    <InputSelect
                      value={parkingType}
                      setValue={(value) => setParkingType(value)}
                      options={allMetadata?.parkingTypes?.map((item) => ({
                        title: item,
                        value: item,
                      }))}
                    />
                  </div>
                </div>

                <div>
                  <span style={styles.label}>Website</span>
                  <input
                    className="input-field"
                    type="text"
                    value={website}
                    style={{
                      fontWeight: 400,
                      fontSize: 16,
                      width: '-webkit-fill-available',
                    }}
                    placeholder="Company Website"
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <span style={styles.label}>Notes for Temps:</span>

                  <textarea
                    className="textarea-style"
                    style={{
                      width: '-webkit-fill-available',
                      height: 60,
                    }}
                    placeholder="Ex: Check in at the front desk."
                    value={notes}
                    onChange={(e) => setOfficeNotes(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  marginTop: 60,
                  marginBottom: 60,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button text="Save" onClick={() => validateGeneralOfficeInfo()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  label: {
    display: 'flex',
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 8,
  },
  text: {
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    fontSize: 16,
    fontWeight: 400,
  },
};
