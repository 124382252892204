import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

const isProduction = process.env.REACT_APP_TEMP_MEE_ENV === 'production';


const prodFirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: "tempmeedemo.firebaseapp.com",
  databaseURL: "https://tempmeedemo.firebaseio.com",
  projectId: "tempmeedemo",
  storageBucket: "tempmeedemo.appspot.com",
  messagingSenderId: "925251549185",
  appId: "1:925251549185:web:a22193c7f14317c95be800",
  measurementId: "G-XLRGQRVNR7"
};

const devFirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: `tempmeedev.firebaseapp.com`,
  databaseURL: `https://tempmeedev.firebaseio.com`,
  projectId: 'tempmeedev',
  storageBucket: `tempmeedev.appspot.com`,
  messagingSenderId:'836175226934',
  appId:  '1:836175226934:web:5211ef5ffc3211bd909097',
  measurementId:  'G-QDV0ZKYKRM',
};

const app = initializeApp(isProduction ? prodFirebaseConfig : devFirebaseConfig);
const remoteConfig = getRemoteConfig(app);

// Fetch and activate Remote Config values
fetchAndActivate(remoteConfig);

export { remoteConfig };
