import moment from "moment";

export const addExpirationDate = (item) => {
  const expirationYear = item.expiry_year;
  const expirationMonth = item.expiry_month;
  const expirationDate = moment({ year: expirationYear, month: expirationMonth - 1 });

  const isExpired = moment(expirationDate, 'MM/YYYY').isBefore(moment());
  return {
    ...item,
    expirationDate,
    isExpired,
  };
}