import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FETCH_TODAY_JOBS_ACTION, reportIncident } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import Button from '../../../commonComponents/Button';
import CustomSpinner from '../../../commonComponents/CustomSpinner';
import arrowRight from '../../../images/arrow_right.svg';
import { Colors } from '../../../themes/colors';
import ConfirmPopup from '../../shared/ConfirmPopup';
import SuccessReportIncidentPopup from '../SuccessReportIncidentPopup';
import { useTable } from '../hooks/useTable';
import ReportIncidentPopup from './ReportIncidentPopup';

import WarningOutlineIcon from '../../../images/warning-outline-icon.svg';
import { UserProfession, UserSubProfession } from '../../../enums/UserProfession';
import { trackEvent } from '../../../api/analytics';

const tableHeaderStyle = {
  fontFamily: 'Nunito',
  fontWeight: 'bold',
  fontSize: '18px',
  color: Colors.primary_500,
  textAlign: 'center',
};

const tableDataBoldStyle = {
  fontFamily: 'Nunito',
  fontWeight: 700,
  fontSize: 18,
  color: Colors.neutral_600,
};

const tableDataNormalStyle = {
  fontFamily: 'Nunito',
  fontWeight: 400,
  fontSize: 16,
  color: Colors.neutral_500,
};

export const Table = ({ limit }) => {
  const dispatch = useDispatch();

  const { jobListScrollRef, onRowClick } = useTable({ type: 'today' });

  const [popupContent, setPopupContent] = useState(null);
  const [showReportIncidentPopup, setShowReportIncidentPopup] = useState(null);
  const [showReportConfirmationPopup, setShowReportConfirmationPopup] = useState(false);
  const showReportIncidentSuccessPopup = useSelector(
    (state) => state.job.showReportIncidentSuccessPopup,
  );
  const jobList = useSelector((state) => state.job.todayJobs);
  const jobListToMap = limit ? jobList?.slice(0, limit) : jobList;

  const loadingSelector = createLoadingSelector([FETCH_TODAY_JOBS_ACTION]);
  const isLoading = useSelector((state) => loadingSelector(state));

  return (
    <>
      {!isLoading && (
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
          }}
        >
          <thead
            style={{
              borderBottom: '12px solid white',
            }}
          >
            <th style={{ opacity: 0, width: 60 }}>#</th>
            <th
              style={{
                ...tableHeaderStyle,
                textAlign: 'left',
                paddingLeft: 16,
              }}
            >
              Professional
            </th>
            <th style={tableHeaderStyle}>Office</th>
            <th style={tableHeaderStyle}>Shift Details</th>
            <th style={tableHeaderStyle}>{/* Report */}</th>
            <th style={{ opacity: 0 }}>#</th>
          </thead>
          <tbody ref={jobListScrollRef}>
            {jobListToMap.map((job) => {
              const canReportIncident = moment().diff(moment(job.shift?.start), 'minutes') >= 30;

              return (
                <tr
                  key={job.id}
                  style={{
                    cursor: 'pointer',
                    borderBottom: `1px solid ${Colors.neutral_100}`,
                    paddingBottom: 8,
                  }}
                  onClick={() => onRowClick(job)}
                >
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <img
                        src={job.professional?.image}
                        style={{
                          width: 60,
                          height: 60,
                          objectFit: 'contain',
                          borderRadius: 60,
                          border: `1px solid ${Colors.neutral_100}`,
                        }}
                        alt=""
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 16,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span style={tableDataBoldStyle}>{`${job.professional?.full_name}`}</span>
                        <span style={tableDataNormalStyle}>
                          {
                            `${UserSubProfession.getName(job.subProfession) 
                                ?? UserProfession.getName(job.profession)}`
                          }
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <span style={tableDataNormalStyle}>{job.offer_owner.office}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          ...tableDataBoldStyle,
                          fontSize: 16,
                          color: Colors.neutral_500,
                        }}
                      >
                        {job.shift?.local_date}
                      </span>
                      <span style={tableDataNormalStyle}>
                        {`${job.shift?.local_start_time} - ${job.shift?.local_end_time}`}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {canReportIncident && (
                        <Button
                          Icon={job.incident ? WarningOutlineIcon : null}
                          iconStyle={{ width: 18, height: 18, marginRight: 0 }}
                          style={{
                            width: 138,
                            height: 30,
                            marginTop: 0,
                            backgroundColor: job.incident ? Colors.neutral_200 : Colors.neutral_400,
                          }}
                          disabled={job.incident}
                          text={
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8,
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: 'Nunito',
                                  fontSize: 12,
                                  fontWeight: 700,
                                }}
                              >
                                {job.incident ? 'Reported' : 'Report Incident'}
                              </span>
                            </div>
                          }
                          onClick={(event) => {
                            event.stopPropagation();
                            setPopupContent({
                              id: job.id,
                              name: `${job.professional?.full_name}`,
                            });
                            setShowReportIncidentPopup(true);
                          }}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={arrowRight}
                        style={{ width: 10, objectFit: 'cover' }}
                        alt="arrow_right"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {jobListToMap?.length === 0 && !isLoading && (
        <div>
          <p className="font_bold" style={{ textAlign: 'center' }}>
            There are no scheduled shifts for today.
          </p>
        </div>
      )}
      {isLoading && <CustomSpinner isLoading={isLoading} />}

      {showReportIncidentPopup && (
        <ReportIncidentPopup
          onCancelClick={() => setShowReportIncidentPopup(false)}
          onConfirmClick={(selectedType) => {
            setPopupContent({
              ...popupContent,
              incidentType: selectedType,
            });
            setShowReportIncidentPopup(false);
            setShowReportConfirmationPopup(true);
          }}
        />
      )}

      {showReportConfirmationPopup && (
        <ConfirmPopup
          title={`Report a ${popupContent?.incidentType}`}
          titleStyle={{ textTransform: 'capitalize' }}
          description={
            <>
              Are you sure you would like to report <strong>{popupContent?.name}</strong> as a{' '}
              {popupContent?.incidentType}?
            </>
          }
          leftButtonText="Cancel"
          leftButtonAction={() => setShowReportConfirmationPopup(false)}
          rightButtonText="Confirm"
          rightButtonAction={() => {
            const job = jobList.find((job) => job.id === popupContent.id);
            const eventName =
              popupContent.incidentType === 'walked-out'
                ? 'Todays Shift - Report Walked Out Submitted'
                : 'Todays Shift - Report No Show Submitted';

            trackEvent(eventName, {
              shiftId: job?.id,
              professionalId: job?.professional?._id,
            });

            dispatch(
              reportIncident({
                jobId: popupContent?.id,
                incidentType: popupContent?.incidentType,
                name: popupContent?.name,
              }),
            );
            setShowReportConfirmationPopup(false);
          }}
        />
      )}

      {showReportIncidentSuccessPopup && <SuccessReportIncidentPopup />}
    </>
  );
};
