import { motion } from 'framer-motion';
import Button from '../../../../commonComponents/Button';
import WarningBanner from '../../../../commonComponents/WarningBanner';
import { UserProfession } from '../../../../enums/UserProfession';
import history from '../../../../history';
import zoomPlusIcon from '../../../../images/zoom_plus.svg';
import { Colors } from '../../../../themes/colors';
import { OfficeTypeEnum } from '../../../../utils/Enum';
import { useCandidate } from '../../hooks/useCandidate';
import { getCompanyMatchLabel } from '../../utils';
import { Content } from './components/Content';
import { Divider } from './components/Divider';
import { Header } from './components/Header';

export const DetailedUserCard = ({ item, user, job }) => {
  const candidate = item?.candidate;

  const { specialty, profession, id: jobId } = job || {};

  const { currentRank, id: candidateId } = candidate || {};

  const { specialtyMatch, insuranceMismatch } = useCandidate(user, candidate, job);

  const companyMatchLabel = getCompanyMatchLabel({
    averageRating: item?.candidate?.average_rating,
    hasBadges: !!item?.candidate?.badges?.length || item?.candidate?.currentRank?.rank,
    hasWorkedWithDSO: item?.hasWorkedWithDSO,
    hasWorkedWithOffice: item?.hasWorkedWithOffice,
    isDSO: user?.office_info?.officeType === OfficeTypeEnum.DSO,
    officeName: user?.office_name,
  });

  const renderWarningDescription = () => {
    if (profession === UserProfession.DN) {
      let description = '';

      if (!specialtyMatch && insuranceMismatch) {
        description =
          'This professional does not have experience in all procedures needed or the same Insurance Provider.';
      } else if (!specialtyMatch) {
        description = 'This professional does not have experience in all procedures needed.';
      } else if (insuranceMismatch) {
        description = 'This professional does not have the same Insurance Provider.';
      }

      return <>{description}</>;
    }

    return (
      <>
        This professional does not have your desired specialty listed as experienced,
        <br />
        <strong>{specialty?.split('_')?.[1]}</strong>.
      </>
    );
  };

  return (
    <motion.div
      key={item.id}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      style={{
        position: 'relative',
        width: 480,
        boxShadow:
          '0px 0.698517px 1.39703px rgba(0, 0, 0, 0.3), 0px 2.09555px 6.98517px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ margin: '0 40px' }}>
        <Header candidate={candidate} item={item} />

        <Divider currentRank={currentRank} badges={candidate?.badges} />

        {!specialtyMatch || insuranceMismatch ? (
          <WarningBanner
            noIcon
            description={renderWarningDescription()}
            indicatorStyle={{ display: 'none' }}
            containerStyle={{
              marginTop: -22,
              marginBottom: 12,
            }}
            iconStyle={{ marginLeft: 20, marginTop: 8, alignSelf: 'flex-start' }}
            primaryColor="#EA7E00"
            secondaryColor="#FEFBF6"
          />
        ) : null}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 24,
          }}
        >
          <Button
            Icon={zoomPlusIcon}
            text="View Application"
            style={{
              width: 250,
              height: 48,
            }}
            onClick={() => history.push(`/dashboard/job/${jobId}/confirm/${candidateId}`)}
          />
        </div>

        {companyMatchLabel && (
          <div style={{ marginBottom: 20, color: Colors.neutral_600, borderBottom: 1 }}>
            <div style={{ marginBottom: 4 }}>
              <b
                style={{
                  fontSize: 18,
                }}
              >
                {companyMatchLabel.label}:
              </b>
            </div>
            <span
              style={{
                fontSize: 16,
              }}
            >
              {companyMatchLabel.text}
            </span>
            <div
              style={{
                height: 1,
                backgroundColor: Colors.neutral_100,
                marginTop: 12,
                marginBottom: 20,
              }}
            />
          </div>
        )}

        <Content user={user} candidate={candidate} job={job} />
      </div>
    </motion.div>
  );
};
