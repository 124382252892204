import React, { useEffect, useRef, useState } from 'react';
import '../../themes/global.scss';
import '../../themes/confirm_hyg.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { createLoadingSelector } from '../../api/selectors';
import {
  postMessage,
  GET_MESSAGES_BY_JOB_ID,
  getMessagesByJobId,
  requestAdminMessage,
} from '../../actions';
import history from '../../history';
import {
  renderUserMessage,
  renderAdminMessage,
  renderOpponentMessage,
  renderSupportCard,
} from './RenderFunc';
import chatSendButton from '../../images/chatSendButton.png';
import GuidelinesPortal from './GuidelinesPortal';
import ChatHeader from './ChatHeader';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import { trackEvent } from '../../api/analytics';

export default ({ match }) => {
  const messageRef = useRef(null);

  const [newMessage, setMessage] = useState('');
  const [isGuidelineOpen, setGuidelineOpen] = useState(false);

  const jobId = history.location.state ? history.location.state.jobId : match.params.jobId;
  const user = useSelector((state) => state.user.user);
  const { currentUserId } = user;
  const selectedJob = useSelector((state) => {
    if (state.job.job?.id === jobId) {
      return state.job.job;
    }

    return null;
  });

  const messagedPerJob = useSelector((state) => state.messages.messagedPerJob);

  const actions = [GET_MESSAGES_BY_JOB_ID];
  const loadingSelector = createLoadingSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [prevIsLoading, setPrevIsLoading] = useState(isLoading);

  const isPostMessageEnabled = moment(selectedJob?.local_date).diff(moment(), 'days') >= 0;

  const dispatch = useDispatch();

  const scrollToBottomMessages = () => messageRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start' });

  const disableRequest = () => !isPostMessageEnabled || messagedPerJob.some((message) => message.type === 'ADMIN-INVITE');

  useEffect(() => {
    dispatch(getMessagesByJobId({ jobId }));

    if (selectedJob?.id === jobId && messagedPerJob.length === 0) {
      setGuidelineOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && prevIsLoading && isPostMessageEnabled) {
      scrollToBottomMessages();
    }

    setPrevIsLoading(isLoading);
  }, [isLoading, messagedPerJob]);

  const renderPostMessageView = () => {
    if (!isLoading && isPostMessageEnabled) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 50,
          }}
        >
          <textarea
            className="form-colntrol textarea"
            placeholder="Enter your message"
            multiple
            value={newMessage}
            onChange={(value) => setMessage(value.target.value)}
          />

          <img
            src={chatSendButton}
            style={{
              width: 50,
              height: 50,
              marginLeft: 30,
              cursor: 'pointer',
            }}
            alt="Send"
            onClick={() => {
              const timeUntilShift = moment(selectedJob?.job_datetime).fromNow(true);
              trackEvent('Job Summary - Contact Professional Button Clicked', {
                hours: selectedJob?.totalHours,
                shiftId: selectedJob?.id,
                shiftSpecialty: selectedJob?.specialty,
                procedures: selectedJob?.procedures,
                breakTime: selectedJob?.lunch_break_time,
                autoConfirm: selectedJob?.autoFill,
                softwares: user?.office_info?.softwares,
                assistedHygiene: selectedJob?.isAssistedHygiene,
                tpp: selectedJob?.amount,
                candidate: {
                  id: selectedJob?.offer_final_reciever?.id,
                  appliedDateTime: selectedJob?.job_filled_datetime,
                  yearOfExperience: selectedJob?.offer_final_reciever?.years_of_experience,
                  schoolName: selectedJob?.offer_final_reciever?.school,
                  graduationYear: selectedJob?.offer_final_reciever?.graduationDate,
                  skills: selectedJob?.offer_final_reciever?.skills,
                  badges: selectedJob?.offer_final_reciever?.badges,
                  rating: selectedJob?.offer_final_reciever?.average_rating,
                },
                timeUntilShift,
              });

              setMessage('');
              dispatch(postMessage({ jobId: selectedJob.id, message: newMessage }));
            }}
          />
        </div>
      );
    }
    return <div />;
  };
  const renderMessages = () => (
    <div
      style={{
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {messagedPerJob.map((messageEl) => {
        if (messageEl.type === 'ADMIN-INVITE') {
          return renderSupportCard();
        }
        if (messageEl.type === 'WARNING') {
          return renderSupportCard(messageEl.text, messageEl.id);
        }
        if (messageEl.author?.isAdmin && messageEl.author?.id !== currentUserId) {
          return renderAdminMessage(
            messageEl.text,
            moment(messageEl.created_at).fromNow(),
            messageEl.isLast,
            messageEl.id,
          );
        }
        if (messageEl.author?.id === currentUserId && messageEl.type === 'MESSAGE') {
          return renderUserMessage(
            messageEl.text,
            moment(messageEl.created_at).fromNow(),
            messageEl.isLast,
            messageEl.id,
          );
        }

        return renderOpponentMessage(
          messageEl.text,
          moment(messageEl.created_at).fromNow(),
          messageEl.isLast,
          messageEl.id,
        );
      })}
      <div style={{ height: 20 }} ref={messageRef} />
    </div>
  );

  return (
    <div
      className="component_container"
      style={{ flexDirection: 'column', justifyContent: 'flex-start', padding: 0 }}
    >
      <ChatHeader
        isDisabledButton={disableRequest()}
        userInfo={selectedJob?.offer_final_reciever}
        jobDate={selectedJob?.local_date}
        inviteAdminPressed={() => dispatch(requestAdminMessage({ jobId }))}
        openGuidelinePressed={() => setGuidelineOpen(true)}
      />

      <div
        className="floating_container"
        style={{
          width: '94%',
          flexDirection: 'column',
          height: '100%',
          marginTop: 0,
        }}
      >
        <div style={{ width: '100%', minHeight: 500 }} className="chat_component">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '70%' }}>{isLoading ? <CustomSpinner isLoading={isLoading} /> : renderMessages()}</div>
          </div>
        </div>
        {renderPostMessageView()}

        {isGuidelineOpen ? (
          <GuidelinesPortal gotItClicked={() => setGuidelineOpen(false)} />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
