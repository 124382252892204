import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { getShouldUsingCredentialServiceEndpoint } from '../growthbook';
import store from '../store/configureStore';

const tempMeeApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

tempMeeApi.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...{ 'x-correlation-id': uuid() },
    ...config.headers,
  },
}));

export const apiMethodsConst = {
  SIGN_IN_REQUEST: 'signInRequest',
  AUTH_ME_REQUEST: 'authMeRequest',
  AUTH_SIGN_UP_REQUEST: 'authSignUpRequest',
  AUTH_LOGOUT_REQUEST: 'authLogoutRequest',
  EDIT_USER_REQUEST: 'editUserRequest',
  ADD_ADDRESS_REQUEST: 'addAddressRequest',
  ADD_OFFICE_PRODUCTIVITY_REQUEST: 'addOfficeProductivityRequest',
  BLOCK_PROFESSIONAL_REQUEST: 'blockProfessionalRequest',
  PHONE_VERIFICATION_REQUEST: 'phoneVerificationRequest',
  SEND_SMS_CODE_REQUEST: 'sendSMSCodeRequest',
  DELETE_ADDRESSES_REQUEST: 'deleteAddressesRequest',
  AUTH_REFRESH_REQUEST: 'authRefreshRequest',
  FEEDBACK_REQUEST: 'feedbackRequest',
  FEEDBACK_SAVE_REQUEST: 'feedbackSaveRequest',
  SEND_PROCEDURE_ENDORSEMENT_REQUEST: 'sendProcedureEndorsementRequest',
  FETCH_USER_PROCEDURE_ENDORSEMENT_REQUEST: 'fetchUserProcedureEndorsementRequest',
  SEND_NPS_REQUEST: 'saveNpsRequest',
  SEND_TEMPMEE_FEEDBACK_REQUEST: 'saveTempMeeFeedbackRequest',
  GET_ALL_PROFESSIONS_REQUEST: 'getAllProfessionsRequest',
  GET_ALL_SPECIALIZATIONS_REQUEST: 'getAllSpecializationsRequest',
  GET_USER_EDUCATION_REQUEST: 'getUserEducationRequest',
  GET_OFFICE_EDUCATION_REQUEST: 'getOfficeEducationRequest',
  UPDATE_USER_EDUCATION_REQUEST: 'updateUserEducationRequest',
  GET_ALL_SOFTWARES_REQUEST: 'getAllSoftwaresRequest',
  ACCEPT_TERMS_REQUEST: 'acceptTermsRequest',
  ACCEPT_PSA_TERMS_REQUEST: 'acceptPSATermsRequest',
  UPLOAD_AVATAR_REQUEST: 'uploadAvatarRequest',

  FORGOT_PASSWORD_REQUEST: {
    SEND_CODE: 'forgotPasswordSendCodeRequest',
    RESET: 'forgotPasswordResetRequest',
  },

  CLOSE_MODAL_ACTION: 'CLOSE_MODAL_ACTION_SUCCESS',
  // dso
  GET_SELECTED_OFFICE_PROFILE_REQUEST: 'getSelectedOfficeProfile',

  // referrals
  SAVE_GIFT_EMAIL_REQUEST: 'saveGiftEmailRequest',
  GET_REFERRALS_REQUEST: 'getReferralRequest',
  CRETE_REFERRALS_REQUEST: 'createReferralRequest',
  PUT_REFERRAL_LINK_REQUEST: 'putReferralLinkRequest',
  CREATE_FIREBASE_LINK: 'createFirebaseLinkRequest',
  // payment
  GET_ALL_PAYMENTS_REQUEST: 'getAllPaymentsRequest',
  ADD_PAYMENT_REQUEST: 'addPaymentRequest',
  GET_PLAID_TOKEN: 'createPlaidToken',
  SUBMIT_PLAID_TOKEN: 'submitPlaidToken',

  DELETE_PAYMENT_REQUEST: 'deletePaymentRequest',
  SELECT_PAYMENT_REQUEST: 'selectPaymentRequest',
  VERIFY_BANK_REQUEST: 'verifyBankRequest',
  // job
  CREATE_JOB_REQUEST: 'createJobRequest',
  FETCH_JOB_REQUEST: 'fetchJobRequest',
  FETCH_CANDIDATE_OVERTIME_REQUEST: 'fetchCandidateOvertimeRequest',
  GET_USER_OVERTIME_LIMITS_REQUEST: 'getUserOvertimeLimitsRequest',
  FETCH_AVERAGE_RATING: 'fetchAverageRatingRequest',
  EDIT_JOB_REQUEST: 'editJobRequest',
  FETCH_OPEN_JOB_REQUEST: 'fetchOpenJobRequest',
  FETCH_SCHEDULED_JOBS_REQUEST: 'fetchScheduledJobsRequest',
  FETCH_ACTION_REQUIRED_JOBS_REQUEST: 'fetchActionRequiredJobsRequest',
  FETCH_TODAY_JOBS_REQUEST: 'fetchTodayJobsRequest',
  GET_ALL_JOBS_REQUEST: 'getAllJobsRequest',
  GET_JOBS_BY_STATUS_DATE_REQUEST: 'getJobsRequestByStatusDate',
  FETCH_JOBS_WITH_TRANSACTIONS: 'fetchJobsWithTransactions',
  CANCEL_JOB_REQUEST: 'cancelJobRequest',
  CONFIRM_JOB_REQUEST: 'confirmJobRequest',
  REPORT_INCIDENT_REQUEST: 'reportIncidentRequest',
  CANCEL_JOB_POSTING_REQUEST: 'cancelJobPostingRequest',
  MULTIDAY_JOB_POSTING_REQUEST: 'multidayJobPostingRequest',
  PP_POSTING_REQUEST: 'ppbPostingRequest',
  DECLINE_COUNTER_OFFER_REQUEST: 'declineCounterOffer',
  FETCH_EXPIRED_COUNTER_OFFERS: 'fetchExpiredCounterOffersRequest',
  FETCH_DASHBOARD_INFO: 'fetchDashboardInfoRequest',
  ADJUST_HOURS_REQUEST: 'adjustHoursRequest',
  FETCH_CANCELLATION_REASONS_REQUEST: 'fetchCancellationReasonsRequest',
  SET_CANCELLATION_REASONS_REQUEST: 'setCancellationReasonsRequest',

  // alerts
  GET_ALERTS_REQUEST: 'getAlertsRequest',
  DELETE_ALERTS_REQUEST: 'deleteAlertsRequest',
  DELETE_ONE_ALERT_REQUEST: 'deleteOneAlertRequest',
  // messages
  GET_MESSAGES_REQUEST: 'getMessagesRequest',
  GET_MESSAGES_BY_JOB_ID_REQUEST: 'getMessagesByJobIdRequest',
  POST_MESSAGE_REQUEST: 'postMessageRequest',
  INVITE_ADMIN_REQUEST: 'inviteAdminRequest',
  // receipts
  FETCH_RECEIPTS_REQUEST: 'fetchReceiptsRequest',
  FETCH_RECEIPTS_REPORT_REQUEST: 'fetchReceiptsReportRequest',
  // invoices
  FETCH_INVOICES_REQUEST: 'fetchInvoicesRequest',

  // pp
  FETCH_ALL_PP_JOBS: 'fetchAllPPJobsRequest',
  FETCH_PP_APPLICATIONS_REQUEST: 'fetchPPApplicationsRequest',
  POST_PP_ACTIONS_REQUEST: 'postPPActionnRequest',
  FETCH_PP_APPLICATION_BY_ID_REQUEST: 'fetchPPApplicationByIdRequest',

  // adjustments
  FETCH_JOBS_WITH_ADJUSTMENTS_REQUEST: 'fetchAdjustmentsRequest',
  CHANGE_STATUS_ADJUSTMENTS_REQUEST: 'changeStatusAdjustmentsRequest',

  // locum tenens dentist
  CREATE_LOCUM_TENENS_DENTIST_REQUEST: 'createLocumTenensDentistRequest',

  // notifications
  FETCH_NOTIFICATION_SETTINGS_REQUEST: 'fetchNotificationSettingsRequest',
  UPDATE_NOTIFICATION_SETTINGS_REQUEST: 'updateNotificationSettingsRequest',

  // favorites
  FETCH_FAVORITE_PROFESSIONALS_REQUEST: 'fetchFavoritesProfessionalRequest',
  DELETE_FAVORITE_PROFESSIONAL_REQUEST: 'deleteFavoriteProfessionalRequest',
  ADD_FAVORITE_PROFESSIONAL_REQUEST: 'addFavoriteProfessionalRequest',
  INVITE_FAVORITE_TO_JOB_REQUEST: 'inviteFavoriteToJobRequest',
  INVITE_PROFESSIONALS_TO_JOBS_REQUEST: 'inviteProfessionalsToJobsRequest',

  // recent professionals
  FETCH_RECENT_PROFESSIONALS_REQUEST: 'fetchRecentProfessionalsRequest',
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_REQUEST: 'fetchOpenJobsForRecentProfessionalRequest',

  // invites
  FETCH_OFFICE_JOB_INVITES_REQUEST: 'fetchOfficeJobInvitesRequest',

  // partnership code
  FETCH_PARTNERSHIP_ORGANIZATIONS_REQUEST: 'fetchPartnershipOrganizationsRequest',
  VERIFY_PARTNERSHIP_CODE_REQUEST: 'verifyPartnershipCodeRequest',

  // metadata
  FETCH_METADATA_REQUEST: 'fetchMetadataRequest',

  // worker type
  SAVE_WORKER_CLASSIFICATION_REQUEST: 'saveWorkerClassificationRequest',
  GET_STATE_WORKER_CLASSIFICATION_REQUEST: 'getStateWorkerClassificationRequest',
  GET_WORKER_CLASSIFICATION_MANAGEMENT: 'getWorkerClassificationManagement',
  SAVE_CHILD_WORKER_CLASSIFICATION: 'saveChildOfficeWorkerClassification',
  SAVE_STATUS_CHILD_WORKER_CLASSIFICATION: 'saveStatusChildOfficeWorkerClassification',
};

async function getAuthHeaders() {
  if (!store.getState().auth.expirationDate || store.getState().auth.expirationDate < new Date()) {
    const response = await callApi('POST', '/auth/refresh', {
      token: localStorage.getItem('refresh_token_dental'),
    });
    store.dispatch({
      type: apiMethodsConst.AUTH_REFRESH_REQUEST,
      payload: response,
    });
  }
  const { accessToken } = store.getState().auth;
  const { user, selectedOfficeId } = store.getState().user;
  if (user && selectedOfficeId && selectedOfficeId !== user.id) {
    return {
      Authorization: accessToken,
      // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      'x-office': selectedOfficeId,
    };
  }
  return {
    Authorization: accessToken,
    // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
}

async function getAuthPPHeaders() {
  if (!store.getState().auth.expirationDate || store.getState().auth.expirationDate < new Date()) {
    const response = await callApi('POST', '/auth/refresh', {
      token: localStorage.getItem('refresh_token_dental'),
    });
    store.dispatch({
      type: apiMethodsConst.AUTH_REFRESH_REQUEST,
      payload: response,
    });
  }
  const { accessToken } = store.getState().auth;
  return {
    Authorization: accessToken,
  };
}

const getHeaders = async (url) => {
  const isAuthRequest = (url.includes('/auth/') || url.includes('/users/password'))
    && url !== '/auth/me'
    && url !== '/auth/logout';

  if (!isAuthRequest) {
    return getAuthHeaders();
  }

  if (url.includes('/auth/refresh')) {
    const fingerprint = await FingerprintJS.load();
    const { visitorId } = await fingerprint.get();
    return { 'x-device-fingerprint': visitorId };
  }

  return {};
};

async function callApi(method, url, body = null, params, extraHeaders) {
  // get store out of the scope of react nad get headers
  const authHeaders = await getHeaders(url);
  const data = method === 'GET' ? { params: body } : { data: body };
  const response = await tempMeeApi({
    method,
    url,
    ...data,
    headers: {...authHeaders, ...extraHeaders},
    params,
  });
  return response.data;
}

async function callApiReturnParam(method, url, body = null, params) {
  // get store out of the scope of react nad get headers
  const isAuthRequest = url.includes('/auth/') && url !== '/auth/me' && url !== '/auth/logout';
  const authHeaders = !isAuthRequest ? await getAuthHeaders() : {};
  const data = method === 'GET' ? { params: body } : { data: body };
  const response = await tempMeeApi({
    method,
    url,
    ...data,
    headers: authHeaders,
    params,
  });
  return body;
}

async function callApiWithValidate(method, url, params) {
  // get store out of the scope of react nad get headers
  const headers = await getAuthHeaders();
  const response = await tempMeeApi({
    method,
    url,
    params,
    headers,
    validateStatus: (status) => (status >= 200 && status < 300) || status === 400,
  });

  return response;
}

async function callPPApi(method, url, body = null, params, isPostedApplicationId) {
  // get store out of the scope of react nad get headers
  const authHeaders = await getAuthPPHeaders();
  const data = method === 'GET' ? { params: body } : { data: body };
  const response = await tempMeeApi({
    method,
    url,
    ...data,
    headers: authHeaders,
    params,
  });
  return isPostedApplicationId
    ? { applicationId: isPostedApplicationId, lastApplicationAction: response.data }
    : response.data;
}

async function createFirebaseLink(referralId) {
  const body = {
    link: `${process.env.REACT_APP_DOMAIN_URI_PREFIX}/referral?referalId=${referralId}`,
    domainUriPrefix: process.env.REACT_APP_DOMAIN_URI_PREFIX,
    iosInfo: {
      appStoreId: process.env.APP_STORE_ID,
      iosBundleId: process.env.APP_BUNDLE_ID,
    },
    androidInfo: {
      androidPackageName: 'com.tempmeeapp',
    },
  };
  const link = await axios.post(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_APIKEY}`,
    { dynamicLinkInfo: body },
  );

  return link.data;
}

const signInRequest = ({ email, password }) => callApi('POST', '/auth/signin', { email, password });
const authMeRequest = () => callApi('GET', '/auth/me'); // TODO: - change to /users/me
const authSignUpRequest = ({ email, password, referral_id }) => callApi('POST', '/auth/signup', {
  email,
  password,
  original: 'web-portal',
  referral_id,
});
const authLogoutRequest = () => callApi('POST', '/auth/logout');
const authRefreshRequest = (token) => callApi('POST', '/auth/refresh', { token });
const forgotPasswordSendCodeRequest = (data) => callApi('PUT', '/users/password', data);
const forgotPasswordResetRequest = (data) => callApi('POST', '/users/password/confirm', data);
const editUserRequest = (data) => callApi('PATCH', '/users', data);
const addAddressRequest = (place_id) => callApi('POST', '/user/address', { place_id, distance: 25 });
const addOfficeProductivityRequest = (productivity) => callApi('PATCH', '/users/office-productivity', { productivity });
const phoneVerificationRequest = (code) => callApi('POST', '/users/phone/confirm', { code });
const sendSMSCodeRequest = (phone) => callApi('PUT', '/users/phone', { phone });
const deleteAddressesRequest = () => callApi('DELETE', '/user/address');
const blockProfessionalRequest = ({ userId }) => callApi('POST', `/users/${userId}/addBlocked`, { userId });
const feedbackRequest = () => callApi('GET', '/feedback/required');
const feedbackSaveRequest = ({ data, jobId }) => callApi('POST', `/review/job/${jobId}`, data);
const sendProcedureEndorsementRequest = ({ endorsement, userId }) => callApi('POST', `/credential-service/users/${userId}/credentials/reviews`, endorsement);
const fetchUserProcedureEndorsementRequest = ({ candidateId, profession }) => callApi('GET', `/credential-service/users/${candidateId}/credentials`, null, { profession });

const saveNpsRequest = (score) => callApi('POST', '/nps-review', { score });
const saveTempMeeFeedbackRequest = (message) => callApi('POST', '/feedback/tempmee', { message });
const getAllProfessionsRequest = () => callApi('GET', '/professions');
const getAllSpecializationsRequest = () => callApi('GET', '/specializations');

/**
 * Getting user's education. Contains user's specialties, procedures, insurances and softwares
 *
 * Use {@link getShouldUsingCredentialServiceEndpoint} feature flag for switching between old and new endpoints.
 *
 * @param {UserProfession} profession
 * @returns {Promise<any>}
 */
const getUserEducationRequest = ({ profession }) => {
  const professionType = profession || 'RDH'

  if (getShouldUsingCredentialServiceEndpoint()) {
    return callApi('GET', `/credential-service/credentials?userType=HYG&profession=${professionType}`)
  }

  // replaced from wrong access from 'DNT' to 'HYG' and eliminates `.DNT` key access
  // to use `.[profession]` key access
  return callApi('GET', `/users/education?userType=HYG&profession=${professionType}`)
};

/**
 * Getting office's education. Contains user's specialties, procedures, insurances and softwares
 *
 * Use {@link getShouldUsingCredentialServiceEndpoint} feature flag for switching between old and new endpoints.
 *
 * @returns {Promise<any>}
 */
const getOfficeEducationRequest = () => {
  if (getShouldUsingCredentialServiceEndpoint()) {
    return callApi('GET', '/credential-service/credentials?userType=DNT')
  }

  // profession need to be declared otherwise returns error
  return callApi('GET', `/users/education?userType=DNT&profession=RDH`)
};
const updateUserEducationRequest = (data) => callApi('PATCH', '/users/specialtiesAndProcedures', data);
const getAllSoftwaresRequest = () => callApi('GET', '/softwares');
const acceptTermsRequest = () => callApi('POST', '/user/tos/gotu');
const acceptPSATermsRequest = () => callApi('POST', '/user/tos/psa');
const uploadAvatarRequest = (data) => callApi('PATCH', '/user/avatar', data, null, { 'Content-Type': 'multipart/form-data' });

// payment requests
const getAllPaymentsRequest = () => callApi('GET', '/payment/account/payment-method/');
const addPaymentRequest = (data) => callApi('POST', '/payment/account/payment-method/', data);
const deletePaymentRequest = (paymentId) => callApi('DELETE', `/payment/account/payment-method/${paymentId}`);
const selectPaymentRequest = (paymentId) => callApi('PATCH', `/payment/account/payment-method/${paymentId}`, {});
const verifyBankRequest = ({ paymentId, data }) => callApi('POST', `/paymentMethods/${paymentId}/verify`, { amounts: data });
const createPlaidToken = () => callApi('POST','/payment/account/plaid/', {});
const submitPlaidToken = (body) => callApi('POST', '/payment/account/plaid/connect', body);
// job
const createJobRequest = (data) => callApi('POST', '/jobs', data);
const fetchJobRequest = (jobId) => callApi('GET', `/jobs/${jobId}`);
const fetchCandidateOvertimeRequest = ({jobId, candidateId}) => callApi('GET', `jobs/${jobId}/candidate/${candidateId}`);
const getUserOvertimeLimitsRequest = () => callApi('GET', `/users/overtime-limits`);
const editJobRequest = ({ jobId, jobData }) => callApi('PATCH', `/jobs/${jobId}`, jobData);
const getAllJobsRequest = (data) => callApi('GET', '/jobs/count-and-dates', {}, data);
const getJobsRequestByStatusDate = ({
  date, status, page = 1, limit = 10,
}) => callApi(
  'GET',
  '/jobs',
  {},
  date
    ? {
      date,
      sortField: 'job_datetime',
      sortOrder: 'asc',
      page,
      limit,
    }
    : {
      status,
      sortField: 'job_datetime',
      sortOrder: status === 'all' || status === 'past' ? 'desc' : 'asc',
      page,
      limit,
    },
);
const fetchJobsWithTransactions = (data) => callApi('GET', '/jobs-with-transactions', {}, data);
const cancelJobRequest = ({jobId}) => callApi('POST', `/jobs/${jobId}/cancel`);
const confirmJobRequest = ({ jobId, data, counterOfferId }) => callApi('POST', `/jobs/${jobId}/confirm/${counterOfferId}`, data);
const cancelJobPostingRequest = (data) => callApi('POST', '/jobs/termsTimeOut', data);
const reportIncidentRequest = ({ jobId, type }) => callApi('POST', `/jobs/${jobId}/incidents`, { type });
const multidayJobPostingRequest = (data) => callApi('POST', '/multi-day', data);
const fetchOpenJobRequest = (data) => callApi('GET', 'jobs/dashboard/open', {}, data);
const fetchScheduledJobsRequest = (data) => callApi('GET', 'jobs/dashboard/filled', {}, data);
const fetchActionRequiredJobsRequest = (data) => callApi('GET', 'jobs/dashboard/action-required', {}, data);
const fetchTodayJobsRequest = (data) => callApi('GET', 'jobs/dashboard/today', {}, data);
const fetchExpiredCounterOffersRequest = (jobId) => callApi('GET', `offers/${jobId}/declined-expired`);
const fetchDashboardInfoRequest = () => callApi('GET', 'jobs/dashboard');

const declineCounterOffer = ({ jobId, counterOfferId, cancellation_reason }) => callApi('DELETE', `jobs/${jobId}/offers/${counterOfferId}`, { cancellation_reason });
const adjustHoursRequest = ({ jobId, data }) => callApi('POST', `jobs/${jobId}/adjust`, data);
const fetchAverageRatingRequest = () => callApi('GET', 'jobs/average-rate');
const fetchCancellationReasonsRequest = () => callApi('GET', 'cancellation-reasons');
const setCancellationReasonsRequest = ({ jobId, data }) => callApi('POST', `/jobs/${jobId}/cancellationFeedback`, data);

// alerts
const getAlertsRequest = (state) => callApi('GET', '/alerts', {}, state);
const deleteAlertsRequest = () => callApi('DELETE', '/alerts/clear');
const deleteOneAlertRequest = (alertId) => callApi('DELETE', `/alerts/${alertId}/read`);
// messages
const getMessagesRequest = (state) => callApi('GET', '/conversations', {}, state);
const getMessagesByJobIdRequest = (jobId) => callApi('GET', `/jobs/${jobId}/messages`);
const postMessageRequest = ({ jobId, message }) => callApi('POST', `/jobs/${jobId}/messages`, { text: message });
const inviteAdminRequest = (jobId) => callApi('POST', `/jobs/${jobId}/invite-admin`, {});
// DSO
const getSelectedOfficeProfile = () => callApi('GET', '/auth/me');

// receipts
const fetchReceiptsRequest = (state) => callApi('GET', '/receipts/dashboard', {}, state);
const fetchReceiptsReportRequest = (state) => callApi('GET', '/receipts/report', {}, state);
// invoice
const fetchInvoicesRequest = (state) => callApi('GET', '/invoices', {}, state);

// PP
const ppbPostingRequest = (data) => callPPApi('POST', '/pp/jobs', data);

const fetchAllPPJobsRequest = () => callPPApi('GET', '/pp/jobs?page=1&limit=10');

const fetchPPApplicationsRequest = async ({ jobId }) => callPPApi('GET', `/pp/jobs/${jobId}`);

const fetchPPApplicationByIdRequest = ({ jobId, applicationId }) => callPPApi('GET', `/pp/jobs/${jobId}/applications/${applicationId}`);
const postPPActionnRequest = ({
  jobId, actionType, applicationId, notes,
}) => callPPApi(
  'POST',
    `/pp/jobs/${jobId}/applications/${applicationId}/action`,
    { action: actionType, notes },
    {},
    applicationId,
);

// referrals
const saveGiftEmailRequest = (email) => callApi('PATCH', '/users/gift-email', { gift_email: email });
const getReferralRequest = (type) => callApiWithValidate('GET', `referral/${type}`, { type });
const createReferralRequest = (type) => callApi('POST', 'referral', { type }, { type });
const putReferralLinkRequest = (referralId, referralLink, type) => callApi('PUT', `referral/${referralId}/url`, { id: referralId, url: referralLink }, { type });
const createFirebaseLinkRequest = (referralId) => createFirebaseLink(referralId);

// adjustments
const fetchAdjustmentsRequest = () => callApi('GET', '/adjustments/required');
const changeStatusAdjustmentsRequest = ({
  jobId, adjustmentId, status, disputeReason,
}) => callApi('PATCH', `/jobs/${jobId}/adjust/${adjustmentId}`, { status, disputeReason });

// locum tenens dentist
const createLocumTenensDentistRequest = (text) => callApi('POST', '/locum-tenens', { text });
// notifications
const fetchNotificationSettingsRequest = () => callApi('GET', '/notification-sender/notification-settings');
const updateNotificationSettingsRequest = (notificationSettings) => callApi('PATCH', '/notification-sender/notification-settings', notificationSettings);

// favorites
const fetchFavoritesProfessionalRequest = (params) => callApi('GET', '/favorite-professionals', {}, params || {});
const deleteFavoriteProfessionalRequest = (id) => callApi('DELETE', `/favorite-professionals/${id}`);
const addFavoriteProfessionalRequest = (id) => callApiReturnParam('PATCH', '/favorite-professionals', { userIds: [id] });
const inviteFavoriteToJobRequest = ({ jobId, userIds }) => callApi('POST', `/jobs/${jobId}/invites`, { userIds });
const inviteProfessionalsToJobsRequest = ({ jobIds, professionalIds }) => callApi('POST', `/jobs/invites`, { jobIds, professionalIds });

const fetchRecentProfessionalsRequest = () => callApi('GET', '/users/recent-professionals');
const fetchOpenJobsForRecentProfessionalRequest = ({ professionalId, ...params}) => callApi('GET', `/offer-discovery/professionals/${professionalId}/offers`, {}, {...params });

// invites
const fetchOfficeJobInvitesRequest = (params) => callApi('GET', `/jobs/invites`, {}, params || {});

// partnership code
const fetchPartnershipOrganizationsRequest = () => callApi('GET', '/partnership-code/organization');
const verifyPartnershipCodeRequest = (data) => callApi('PATCH', '/users', data);

// metadata
const fetchMetadataRequest = () => callApi('GET', '/metadata');

// worker type
const saveWorkerClassificationRequest = (type) => callApi('PATCH', '/users/worker-classification', type);
const saveChildOfficeWorkerClassification = (params) => callApi('PATCH', `/users/${params.id}/worker-classification`, { type: params.workerType });
const saveStatusChildOfficeWorkerClassification = (params) => callApi('POST', `/users/worker-classification/${params.id}`, { status: params.status });
const getStateWorkerClassificationRequest = () => callApi('GET', '/users/state-worker-classification');
const getWorkerClassificationManagement = () => callApi('GET', '/users/worker-classification');

export const apiMethods = {
  signInRequest,
  authMeRequest,
  authSignUpRequest,
  authLogoutRequest,
  forgotPasswordSendCodeRequest,
  forgotPasswordResetRequest,
  editUserRequest,
  addAddressRequest,
  addOfficeProductivityRequest,
  phoneVerificationRequest,
  sendSMSCodeRequest,
  deleteAddressesRequest,
  blockProfessionalRequest,
  feedbackRequest,
  authRefreshRequest,
  feedbackSaveRequest,
  sendProcedureEndorsementRequest,
  fetchUserProcedureEndorsementRequest,
  saveNpsRequest,
  saveTempMeeFeedbackRequest,
  acceptTermsRequest,
  acceptPSATermsRequest,
  uploadAvatarRequest,
  // paymnet
  selectPaymentRequest,
  deletePaymentRequest,
  addPaymentRequest,
  getAllPaymentsRequest,
  verifyBankRequest,
  createPlaidToken,
  submitPlaidToken,
  // job
  fetchAverageRatingRequest,
  createJobRequest,
  getAllJobsRequest,
  editJobRequest,
  fetchJobRequest,
  fetchCandidateOvertimeRequest,
  getUserOvertimeLimitsRequest,
  cancelJobRequest,
  confirmJobRequest,
  reportIncidentRequest,
  getJobsRequestByStatusDate,
  fetchJobsWithTransactions,
  cancelJobPostingRequest,
  multidayJobPostingRequest,
  ppbPostingRequest,
  fetchOpenJobRequest,
  fetchScheduledJobsRequest,
  fetchActionRequiredJobsRequest,
  fetchTodayJobsRequest,
  declineCounterOffer,
  fetchExpiredCounterOffersRequest,
  fetchDashboardInfoRequest,
  adjustHoursRequest,
  fetchCancellationReasonsRequest,
  setCancellationReasonsRequest,
  // alerts
  getAlertsRequest,
  deleteAlertsRequest,
  deleteOneAlertRequest,
  getAllProfessionsRequest,
  getAllSpecializationsRequest,
  getUserEducationRequest,
  getOfficeEducationRequest,
  updateUserEducationRequest,
  getAllSoftwaresRequest,
  // messages
  getMessagesRequest,
  getMessagesByJobIdRequest,
  postMessageRequest,
  inviteAdminRequest,
  // dso
  getSelectedOfficeProfile,
  // receipts
  fetchReceiptsRequest,
  fetchReceiptsReportRequest,
  // invoice
  fetchInvoicesRequest,
  // pp
  fetchAllPPJobsRequest,
  fetchPPApplicationByIdRequest,
  postPPActionnRequest,
  fetchPPApplicationsRequest,
  // referrals
  saveGiftEmailRequest,
  getReferralRequest,
  createReferralRequest,
  putReferralLinkRequest,
  createFirebaseLinkRequest,
  // adjustmants
  fetchAdjustmentsRequest,
  changeStatusAdjustmentsRequest,
  // locum tenens dentist
  createLocumTenensDentistRequest,
  // notifications
  fetchNotificationSettingsRequest,
  updateNotificationSettingsRequest,
  // favorites
  fetchFavoritesProfessionalRequest,
  deleteFavoriteProfessionalRequest,
  addFavoriteProfessionalRequest,
  inviteFavoriteToJobRequest,
  inviteProfessionalsToJobsRequest,
  fetchRecentProfessionalsRequest,
  fetchOpenJobsForRecentProfessionalRequest,
  // invites
  fetchOfficeJobInvitesRequest,
  // partnership code
  fetchPartnershipOrganizationsRequest,
  verifyPartnershipCodeRequest,
  // metadata
  fetchMetadataRequest,
  // worker type
  saveWorkerClassificationRequest,
  getStateWorkerClassificationRequest,
  getWorkerClassificationManagement,
  saveChildOfficeWorkerClassification,
  saveStatusChildOfficeWorkerClassification,
};
