import { motion } from 'framer-motion';
import { useState } from 'react';
import { UserSubProfession } from '../../../../enums/UserProfession';
import { Colors } from '../../../../themes/colors';
import { CompanyMatchLabel } from './components/CompanyMatchLabel';
import { Content } from './components/Content';
import { Divider } from './components/Divider';
import { Footer } from './components/Footer';
import { Header } from './components/Header';

const borderColorVariants = {
  active: {
    borderColor: Colors.secondary_500,
  },
  inactive: {
    borderColor: Colors.white,
  },
};

export const UserCard = ({ item, job, isSelected, onClick }) => {
  const [isHovering, setIsHovering] = useState(false);
  const { candidate } = item;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        initial="inactive"
        animate={isSelected || isHovering ? 'active' : 'inactive'}
        transition={{ duration: 0.2 }}
        variants={borderColorVariants}
        style={{
          borderWidth: 2,
          borderStyle: 'solid',
        }}
      >
        <button
          type="button"
          style={{
            all: 'unset',
            position: 'relative',
            width: 502,
            boxShadow:
              '0px 0.698517px 1.39703px rgba(0, 0, 0, 0.3), 0px 2.09555px 6.98517px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
          }}
          onClick={() => onClick(item)}
          onFocus={handleMouseOver}
          onBlur={handleMouseOut}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div
            style={{
              padding: '12px 18px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <CompanyMatchLabel
                professional={{
                  ...item?.candidate,
                  hasWorkedWithDSO: item?.hasWorkedWithDSO,
                  hasWorkedWithOffice: item?.hasWorkedWithOffice,
                }}
              />
            </div>
            <Header
              fullName={`${candidate?.first_name} ${candidate?.last_name}`}
              rating={candidate?.average_rating?.toFixed(1) || '5.0'}
              professionName={UserSubProfession.getName(job?.subProfession) ?? job?.professionName}
              imageSrc={candidate?.profile_image}
              isFavorite={item.isFavorite}
              currentRank={candidate?.currentRank}
            />
          </div>

          <div
            style={{
              margin: '12px 0px 16px 0px',
              width: '100%',
            }}
          >
            <Divider currentRank={candidate?.currentRank} badges={candidate?.badges} />
          </div>

          <div>
            <Content
              profession={job?.profession}
              multipleCounters={job?.multipleCounters}
              candidate={candidate}
            />
          </div>

          <div
            style={{
              padding: '12px 18px',
            }}
          >
            <Footer item={item} url={`/dashboard/job/${job.id}/confirm/${candidate.id}`} />
          </div>
        </button>
      </motion.div>
    </motion.div>
  );
};
