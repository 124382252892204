import { useMemo } from 'react';
import { Colors } from '../../../../../themes/colors';
import { getShouldFilterRenouncedProcedures } from '../../../../../growthbook';
import { EducationUtils } from '../../../../../utils/EducationUtils';

export const SpecialtiesAndProceduresView = ({
  candidateSpecialties,
  specialtiesAndProcedures,
  candidateProfession,
  procedureEndorsement,
}) => {
  const endorsedProcedures = useMemo(() => {
    const procedures = EducationUtils.getProceduresOfProfession({
      specialtiesAndProcedures,
      profession: candidateProfession,
      specialties: candidateSpecialties,
    });

    if (getShouldFilterRenouncedProcedures() && procedureEndorsement) {
      const endorsements = procedureEndorsement.education?.specialtiesAndProcedures ?? [];

      return EducationUtils.getEndorsedProcedures({
        endorsements,
        procedures,
        profession: candidateProfession,
      });
    }

    return procedures;
  }, [specialtiesAndProcedures, candidateProfession, candidateSpecialties, procedureEndorsement]);

  if (specialtiesAndProcedures && Object.entries(specialtiesAndProcedures)?.length > 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          color: Colors.neutral_600,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <b style={{ ...styles.title, marginBottom: 20 }}>Procedures:</b>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          {specialtiesAndProcedures &&
            Object.entries(specialtiesAndProcedures)?.map(([specialty, procedures]) => {
              const onlyEndorsedProcedures = (procedures ?? [])
                .filter((procedure) => endorsedProcedures.includes(procedure));

              // show specialty if there are endorsed procedures
              // this to hide specialty for being alone displayed as title
              if (onlyEndorsedProcedures.length > 0) {
                return (
                  <div key={specialty}>
                    <div style={{ textAlign: 'center' }}>
                      <span style={styles.text}>
                        <b>
                          {EducationUtils.removeSpecialtyPrefix(specialty)}
                        </b>
                      </span>
                    </div>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 12,
                      }}
                    >
                      {(procedures ?? [])
                        .filter((procedure) => endorsedProcedures.includes(procedure))
                        .map((procedure) => (
                          <li style={styles.text} key={procedure}>
                            {EducationUtils.removeProcedurePrefix(procedure)}
                          </li>
                        ))}
                    </ul>
                  </div>
                );
              }

              return <></>;
            })}
        </div>
      </div>
    );

  }

  return <></>;
};

const styles = {
  title: {
    fontSize: 18,
  },
  text: {
    fontSize: 16,
  },
};
