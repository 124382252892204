import Button from '../commonComponents/Button';
import browserHistory from '../history';
import { Colors } from '../themes/colors';

export default function SaveNotificationSettingsPopup({
  showSaveSettingsPopup,
  setShowSaveSettingsPopup,
}) {
  if (showSaveSettingsPopup) {
    return (
      <div
        className="modal"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <div
          style={{
            width: 500,
            backgroundColor: Colors.white,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 14,
          }}
        >
          <p
            className="global_font f-dark"
            style={{
              textAlign: 'center',
              width: '85%',
              margin: '40px 0px 30px 0px',
            }}
          >
            {showSaveSettingsPopup === 'reminder'
              ? 'Are you sure you would like to leave this page without saving the changes made to your notification preferences?'
              : 'Your notification preferences have been updated!'}
          </p>

          <div style={{ display: 'flex', gap: 16, marginBottom: 30 }}>
            {showSaveSettingsPopup === 'reminder' ? (
              <>
                <Button 
                  text="Go Back" 
                  onClick={() => setShowSaveSettingsPopup(false)} 
                  style={{
                    width: 150,
                    backgroundColor: Colors.secondary_500,
                  }}
                />

                <Button 
                  text="Leave" 
                  onClick={() => browserHistory.goBack()}
                  style={{
                    width: 150,
                    backgroundColor: Colors.primary_500,
                  }}
                />
              </>
            ) : (
              <Button
                text="Done"
                onClick={() => browserHistory.goBack()}
                style={{
                  width: 150,
                  backgroundColor: Colors.primary_500,
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}
