import { useSelector } from 'react-redux';
import { Colors } from '../../../../../themes/colors';
import { OfficeTypeEnum } from '../../../../../utils/Enum';
import { getCompanyMatchLabel } from '../../../utils';

export const CompanyMatchLabel = ({ professional }) => {
  const user = useSelector((state) => state.user.user);
  const companyMatchLabel = getCompanyMatchLabel({
    averageRating: professional?.average_rating,
    hasBadges: !!professional?.badges?.length || professional?.currentRank?.rank,
    hasWorkedWithDSO: professional?.hasWorkedWithDSO,
    hasWorkedWithOffice: professional?.hasWorkedWithOffice,
    isDSO: user?.office_info?.officeType === OfficeTypeEnum.DSO,
    officeName: user?.office_name,
  });

  if (!companyMatchLabel) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'flex',
        marginTop: 8,
        marginBottom: -16,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 20,
          backgroundColor: Colors.primary_100,
          borderRadius: 4,
          width: 'fit-content',
          padding: '0 8px',
        }}
      >
        <span
          style={{
            fontFamily: 'Nunito',
            fontSize: 13,
            fontWeight: 700,
            color: Colors.secondary_800,
            textTransform: 'uppercase',
          }}
        >
          {companyMatchLabel.label}
        </span>
      </div>
    </div>
  );
};
