import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_JOB_ACTION,
  FETCH_USER_PROCEDURE_ENDORSEMENT,
  fetchJob,
  fetchUserProcedureEndorsement,
} from '../../actions';
import BackButton from '../../commonComponents/BackButton';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import history from '../../history';
import useLoading from '../../hooks/useLoading';
import { DetailedUserCard } from './components/DetailedUserCard/DetailedUserCard';
import { UserCard } from './components/UserCard/UserCard';
import { getShouldFilterRenouncedProcedures } from '../../growthbook';
import { trackEvent } from '../../api/analytics';

export default function CandidateList({ match }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const job = useSelector((state) => state.job.job);
  const jobId = match?.params?.jobId;
  const prevScreen = history.location.state?.prevScreen;

  const { isLoading } = useLoading([FETCH_JOB_ACTION, FETCH_USER_PROCEDURE_ENDORSEMENT]);

  const [selectedUser, setSelectedUser] = useState(job?.multipleCounters?.[0]);
  const isFirstRenderRef = useRef(true);

  const {
    id: candidateId,
    license: { profession: candidateProfession } = {},
  } = selectedUser?.candidate || {}

  const handleUserCardClick = (item) => {
    setSelectedUser(item);
  };

  useEffect(() => {
    if (job && prevScreen === 'pending') {
      trackEvent('Shifts - Action Needed Tab - Shift Tile Clicked', {
        shiftId: job?.id,
        shiftSpecialty: job?.specialty,
        procedures: job?.procedures,
        breakTime: job?.lunch_break_time,
        autoConfirm: job?.autoFill,
        softwares: user?.office_info?.softwares,
        assistedHygiene: job?.isAssistedHygiene,
        tpp: job?.amount,
        candidates: job?.multipleCounters?.map((item) => ({
          id: item?.candidate?.id,
          appliedDateTime: item?.created_at,
        })),
        pendingProfessionalsCount: job?.multipleCounters?.filter((item) => item?.status === 'active')?.length,
        expiredProfessionalsCount: job?.multipleCounters?.filter((item) => item?.status === 'expired')?.length,
      });
    }
  }, [job, prevScreen]);

  useEffect(() => {
    if (job && selectedUser) {
      trackEvent('Candidates Screen - View Application Clicked', {
        hours: job?.hours,
        shiftId: job?.id,
        shiftSpecialty: job?.specialty,
        procedures: job?.procedures,
        breakTime: job?.lunch_break_time,
        autoConfirm: job?.autoFill,
        softwares: user?.office_info?.softwares,
        assistedHygiene: job?.isAssistedHygiene,
        tpp: job?.amount,
        candidate: {
          id: selectedUser?.candidate?.id,
          appliedDateTime: selectedUser?.created_at,
          yearOfExperience: selectedUser?.candidate?.years_of_experience,
          schoolName: selectedUser?.candidate?.school,
          graduationYear: selectedUser?.candidate?.graduationDate,
          skills: selectedUser?.candidate?.skills,
          badges: selectedUser?.candidate?.badges,
          rating: selectedUser?.candidate?.average_rating,
        },
      });
    }
  }, [job, selectedUser]);

  useEffect(() => {
    if (isFirstRenderRef.current) {
      return;
    }

    if (job && !job?.multipleCounters?.length) {
      history.replace('/');
      return;
    }

    if (job && job?.multipleCounters?.length) {
      setSelectedUser(job?.multipleCounters[0]);
    }
  }, [dispatch, job, jobId]);

  useEffect(() => {
    if ((isFirstRenderRef.current || !job) && !isLoading) {
      dispatch(fetchJob({ jobId }));

      isFirstRenderRef.current = false;
    }
  }, [dispatch, job, isLoading, jobId]);

  useEffect(() => {
    if (candidateId && candidateProfession && getShouldFilterRenouncedProcedures()) {
      dispatch(fetchUserProcedureEndorsement({
        candidateId,
        profession: candidateProfession,
      }));
    }
  }, [candidateId, candidateProfession, dispatch]);

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingBottom: 120,
        }}
      >
        <div style={{ width: '95%' }}>
          <BackButton />
        </div>

        <h1 className="global_font f-dark" style={{ marginBottom: 30 }}>
          Candidates
        </h1>

        {isLoading && <CustomSpinner isLoading />}

        {!isLoading && selectedUser && (
          <>
            <div style={{ display: 'flex', gap: 8 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                {job?.multipleCounters?.map((item) => (
                  <UserCard
                    key={item.id}
                    item={item}
                    isSelected={selectedUser?.candidate?.id === item?.candidate?.id}
                    job={job}
                    onClick={handleUserCardClick}
                  />
                ))}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DetailedUserCard item={selectedUser} user={user} job={job} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
