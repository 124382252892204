export const StatusEnum = {
  pending: 'ACTION NEEDED',
  completed: 'COMPLETED',
  scheduled: 'SCHEDULED',
  today: 'TODAY',
  open: 'OPEN',
  cancelled: 'CANCELLED',
  expired: 'EXPIRED',
};

export const FilterStatusEnum = {
  ALL: 'all',
  COMPLETED: 'past',
  PENDING: 'pending',
  PENDING_COUNTER: 'pending-counter',
  COUNTER: 'counter',
  SCHEDULED: 'filled',
  TODAY: 'today',
  OPEN: 'open',
};

export const OfficeTypeEnum = {
  DSO: 'dso',
  PRIVATE_PRACTICE_MULTI: 'private_practice_multi',
  PRIVATE_PRACTICE_INDEPENDENT: 'private_practice_independent',
};