export const getAutoFillInfo = (professionType, specialty) => {
  // FIXME(lucky): use code instead of name for consistency
  if (['Associate Dentist', 'Front Desk'].includes(professionType)) {
    return {
      show: false,
      message: `The Auto-Confirm feature is not available for ${professionType} at this time.`,
    };
  }

  if (!specialty?.toLowerCase()?.includes('general')) {
    return {
      show: false,
      message:
        'The Auto-Confirm feature is only available for shifts without a specialty preference (General).',
    };
  }

  return {
    show: true,
    message:
      'By turning on auto-confirm, GoTu will confirm an eligible professional. Click the info button for eligibility criteria details.',
  };
};

const baseUrl = 'https://joingotu.com';

export const Links = {
  home: baseUrl,
  about: `${baseUrl}/about`,
  getHelp: `${baseUrl}/get-help`,
  dentistsSideFaqs: `${baseUrl}/dentists-side-faqs`,
  temporaryStaffing: `${baseUrl}/dental-offices/temporary-staffing`,
  bankPayments: `${baseUrl}/tempmee-bank-payments`,
  privacyPolicy: `${baseUrl}/privacy-policy`,
  termsOfUse: `${baseUrl}/client-terms-of-use`,
  termsOfUseEmployee: `${baseUrl}/w-2-psa/`,
  dentalOffices: `${baseUrl}/dental-offices`,
  hygienist: `${baseUrl}/hygienist`,
  tempmeeBecomingGoTu: `${baseUrl}/blog/tempmee-is-becoming-gotu`,
};

export const paymentHistoryFilterOptions = {
  ALL: 'All',
  CURRENT_MONTH: 'CURRENT_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  LAST_3_MONTHS: 'LAST_3_MONTHS',
  LAST_6_MONTHS: 'LAST_6_MONTHS',
  YEAR_TO_DATE: 'YEAR_TO_DATE',
  LAST_YEAR: 'LAST_YEAR',
};

export const paymentHistoryFilterOptionsLabels = {
  [paymentHistoryFilterOptions.ALL]: 'All',
  [paymentHistoryFilterOptions.CURRENT_MONTH]: 'Current Month',
  [paymentHistoryFilterOptions.LAST_MONTH]: 'Last Month',
  [paymentHistoryFilterOptions.LAST_3_MONTHS]: 'Previous 3 Months',
  [paymentHistoryFilterOptions.LAST_6_MONTHS]: 'Previous 6 Months',
  [paymentHistoryFilterOptions.YEAR_TO_DATE]: 'Year to Date',
  [paymentHistoryFilterOptions.LAST_YEAR]: 'Last Year',
};

export const badRatingReasonOptions = [
  { title: 'Arrived late', value: 'arrivedLate' },
  { title: 'Left early', value: 'leftEarly' },
  { title: 'Not the right attitude', value: 'notTheRightAttitude' },
  { title: 'Couldn’t perform requested procedures', value: 'couldntPerformRequestedProcedures' },
  { title: 'Lack of experience', value: 'lackOfExperience' },
  { title: 'Not gentle with patients', value: 'notGentleWithPatients' },
  { title: 'Other (Explain further in the Feedback box below)', value: 'other' },
];
