import { Colors } from '../../../../../themes/colors';
import { EducationUtils } from '../../../../../utils/EducationUtils';
import { useMemo } from 'react';
import { getShouldFilterRenouncedProcedures } from '../../../../../growthbook';

export const ProceduresView = ({
  candidateProcedures,
  candidateProfession,
  procedureEndorsement,
}) => {
  const procedures = useMemo(() => {
    const procedures = candidateProcedures ?? []

    if (getShouldFilterRenouncedProcedures() && procedureEndorsement) {
      const endorsements = procedureEndorsement.education?.specialtiesAndProcedures ?? [];

      return EducationUtils.getEndorsedProcedures({
        endorsements,
        procedures,
        profession: candidateProfession,
      });
    }

    return procedures;
  }, [candidateProcedures, candidateProfession, procedureEndorsement]);

  if (procedures.length > 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          color: Colors.neutral_600,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <b style={{ ...styles.title, marginBottom: 20 }}>Procedures:</b>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          {procedures.map((name) => (
            <li key={name} style={styles.text}>
              {EducationUtils.removeProcedurePrefix(name)}
            </li>
          ))}
        </div>
      </div>
    );
  }

  return <></>
}

const styles = {
  title: {
    fontSize: 18,
  },
  text: {
    fontSize: 16,
  },
};
